body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Fredoka";
}


.carousel .control-dots .dot {
  background-color: #f55e3f !important;
}